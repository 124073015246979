"use client";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "@/libs/translation/languages/en-US.json";
import hy from "@/libs/translation/languages/hy-HY.json";
import { PropsWithChildren } from "react";

const resources = {
    en,
    hy
};
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "hy", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        fallbackLng: "hy",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });
export const TranslateProvider: React.FC<PropsWithChildren> = ({
                                                                   children
                                                               }): JSX.Element => {
    return (
        <>{children}</>
    );
};
